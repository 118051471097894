import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { dateFnsLocales } from '@engined/core/helpers/date.js';
import { Resource } from '@engined/core/services/i18n.js';
import { Box } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';

interface OwnProps {
  date: string | Date;
  format: Resource | string;
  /**
   * dateTime attribute of <time> element
   */
  dateTime?: string | null;
}

type Props = OwnProps;

const FormatDate: React.FunctionComponent<Props> = ({ date, format, dateTime = null }) => {
  const { t, language } = useLocale();

  const localizedFormat = typeof format === 'string' ? format : t(format);

  if (!dateTime) {
    const hasDate = localizedFormat.includes('dd');
    const hasTime = localizedFormat.includes('HH');
    const hasSeconds = localizedFormat.includes('ss');
    if (hasDate && hasTime) {
      dateTime = date instanceof Date ? date.toISOString() : date;
    } else if (hasTime) {
      dateTime = hasSeconds
        ? formatInTimeZone(new Date(date), ENV.TZ, 'HH:mm:ss')
        : formatInTimeZone(new Date(date), ENV.TZ, 'HH:mm');
    } else if (hasDate) {
      dateTime = new Date(date).toISOString().slice(0, 10);
    }
  }

  return (
    <Box component="time" suppressHydrationWarning dateTime={dateTime}>
      {formatInTimeZone(new Date(date), ENV.TZ, localizedFormat, {
        locale: dateFnsLocales[language],
      })}
    </Box>
  );
};

FormatDate.displayName = 'FormatDate';

export default FormatDate;
